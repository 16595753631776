import { Add, Draw, Email, ExpandLess, ExpandMore, Help, HelpOutline, QuestionAnswer, SafetyCheck, Star, Verified, VerifiedOutlined } from "@mui/icons-material";
import { Modal, Pagination, Stack, Paper, FormControl, Box, InputLabel, Select, MenuItem, Link, TextField, Button, Avatar, CardActionArea, Card, CardContent, Typography, useMediaQuery, ButtonBase, List, Breadcrumbs, IconButton, SwipeableDrawer, Drawer, ListItemIcon, Checkbox, Chip, Menu, Grid, Backdrop, FormControlLabel, Tooltip, DialogActions, OutlinedInput, ListItemText, ToggleButton, Badge, Accordion, AccordionSummary, AccordionDetails, styled, Popper, Popover } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";



import { useNavigate, useParams } from "react-router-dom";
import { Divider, ListItem, ListItemButton } from "@mui/material";

import ProfileDialog from "../components/ProfileDialog.js";
import { hasRole, unitRoles, unitRoleNames, features } from "../features/access.js";
import { useTheme } from "@emotion/react";
import CreateTag from "../components/CreateTag.js";
import jax from "../helper/jax.js";
import Expando from "../components/Expando.js";
import { getSearchClient } from "../helper/search.js";
import { Controller, useForm } from "react-hook-form";
import AgencyAvatar from "../components/AgencyAvatar.tsx";
import { hasFeature } from "../store.js";
import Grid2 from "@mui/material/Unstable_Grid2";
import { BadgeIcon, TrackIcon } from "./Tracks.tsx";
import Notification from "../components/Notification.js";




function SendMessage(props) {
	const {unit_id, user, selected, show, onClose} = props;

	const [sending, setSending] = useState(false);

	const send = (data,e) => {
		setSending(true);
		try {
			jax.post('/app/admin/personnel/message', {unit_id: unit_id, subject: data.subject, title: data.title, message: data.message, users: selected.map((u)=>u.uid)}).then((resp)=>{
				
				onClose();
			});
		} finally {
			setSending(false);
		}
	}

	const {handleSubmit, control, reset} = useForm({
		defaultValues: {
			subject: "",
			message: "",
			title: ""
		}
	});

	useEffect(()=>{
		reset();
	}, [show]);

	return <Dialog open={!!show} onClose={onClose} fullWidth>
		<Box className="card-header">Send a Message</Box>
		
		<DialogContent dividers>
			<Box pt={1}>
				Your message will be sent to <b>{selected.length} user{selected.length > 1 ? "s" : ""}</b> from <b>messages@valkyrietraining.org</b>.
			</Box>

			<form onSubmit={handleSubmit(send)}>
				<Stack mt={3} spacing={3}>
					<FormControl fullWidth>
						<Controller control={control} name="subject" rules={{required: 'Required'}}  render={({field})=><TextField fullWidth label="Subject" size="small" {...field} required></TextField>}></Controller>
					</FormControl>
					<FormControl fullWidth>
						<Controller control={control} name="title" render={({field})=><TextField fullWidth label="Heading / Title" size="small" {...field}></TextField>}></Controller>
					</FormControl>
					<FormControl fullWidth>
						<Controller control={control} name="message" rules={{required: 'Required'}}  render={({field})=><TextField fullWidth label="Message" size="small" {...field} multiline rows={6} required></TextField>}></Controller>
					</FormControl>
				</Stack>

				<Stack direction="row" mt={2} spacing={1} justifyContent="space-between" flex={1} pt={2}>	
					<Button onClick={onClose} variant="text" color="secondary">Cancel</Button>
					<Button type="submit" variant="contained" color="primary" disabled={sending}>Send</Button>
				</Stack>
			</form>  
		</DialogContent>
	</Dialog>;
}

function UserRow(props) {
	const {user, onClick, selected, onSelect, myTags, onRemoveTag} = props;
	const affiliations = useSelector((state) => state.data.affiliations);
	const agencies = useSelector((state) => state.data.agencies);
	const ranks = useSelector((state) => state.data.ranks);
	const roles = useSelector((state) => state.data.roles);	
	const [tags, setTags] = useState([]);
	
	var affiliation = affiliations.find(x=>x.id == user.affiliation_id);
	var agency = agencies.find(x=>x.id == user.agency_id);
	var unit_agency = agencies.find(x=>x.id == user.unit_agency_id);
	var rank = ranks.find(x=>x.id == user.rank_id);
	var role = roles.find(x=>x.id == user.valk_role_id);
	
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

	

	const nav = useNavigate();

	

	useEffect(()=>{
		if (myTags?.length) {
			var t = user.user_tags?.map((tag_id)=>myTags.find((tag)=>tag.id == tag_id)).filter(x=>!!x);
			setTags(t);
		}
	}, [user.user_tags,myTags]);

	return <ListItem onClick={()=>onClick && onClick(user.uid)}  sx={{p:0, py: isMobile ? 1 : 0, display:'block'}} className={selected ? "primary bg" : ""}>
		<ListItemButton sx={{display:'block', padding: 0}}>
			<Stack alignItems={{xs: 'flex-start', lg: 'center'}} justifyContent="flex-start" direction={{xs:'column', lg:'row'}} spacing={{xs:0.5, lg:2}}>
				
				<Stack direction="row" flex={4} flexGrow={false} flexShrink={false} sx={{overflow:"hidden", textOverflow:"ellipsis", marginLeft:"0 !important"}}>
					<Checkbox
						color="primary"
						checked={selected}
						mr={0}
						value={user.uid}
						sx={{p:1.5}}
						onClick={(e)=>{e.stopPropagation();}}
						onChange={(e)=>{e.stopPropagation(); onSelect && onSelect(user, e.currentTarget.checked)}}
					/>
					
					<Stack direction="row" alignItems="center" sx={{overflow:"hidden", textOverflow:"ellipsis"}}  mt={0}  pr={2}>
						<Avatar variant="rounded" src={user.photo} sx={{border:"solid 2px white", height: 32, cursor: 'pointer', width: 32, mr:1 }} />

						<Box sx={{overflow:"hidden", textOverflow:"ellipsis"}}>
							<b>{rank?.abbr} {user.name}</b>
							<div className="sub-title xs">{affiliation?.name} {agency?.name}</div>
						</Box>
					</Stack>
				</Stack>

				{hasFeature(features.COMMAND_MANAGEMENT) && <Stack direction="row" alignItems="center" flex={4} flexGrow={false} flexShrink={false} sx={{overflow:"hidden", textOverflow:"ellipsis"}} mt={0} pl={isMobile ? 6.5 : 0}>
					{user.unit_id && <AgencyAvatar agency_id={unit_agency?.id} size={24}  sx={{border:"solid 2px white", height: 24, cursor: 'pointer', width: 24, mr:1 }}/>}
					{!user.unit_id && user.unit_name && <AgencyAvatar size={24}  sx={{border:"solid 2px white", height: 24, cursor: 'pointer', width: 24, mr:1 }}/>}
					
					{user.unit_id ? <><Box sx={{overflow:"hidden", textOverflow:"ellipsis"}} >
						{user.unit_name}
						<div className="sub-title xs">{user.unit_location}</div>
					</Box></> : <div className="sub-title"><i>{user.unit_name}</i></div>}
				</Stack>}

				
				<UserRoles size="small" user={user}/>

			</Stack>
		</ListItemButton>
	</ListItem>;
}



export default function Personnel(props) {
	const {load, isActive, unit} = props;
	const {config} = useSelector((state) => state.data);
	const {user, roleMap, badges, tracks} = useSelector((state) => state.data);
	const tenantInfo = useSelector(state=>state.app.tenantInfo);
	const search_client = getSearchClient(tenantInfo?.sk);
	
	const index = search_client.initIndex(config.personnel_index);
	
	// create a mui horizontal Stack with 4 dropdowns

	const {user_id, object_id, tab} = useParams();
	const prms = useParams();
	
	const affiliations = useSelector((state) => state.data.affiliations);
	const agencies = useSelector((state) => state.data.agencies);
	const ranks = useSelector((state) => state.data.ranks);
	const roles = useSelector((state) => state.data.roles);
	
	const [name, setName] = useState("");
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [selectedTracks, setSelectedTracks] = useState([]);
	const [selectedBadges, setSelectedBadges] = useState([]);
	const [verification, setVerification] = useState("");
	const [unitRole, setUnitRole] = useState("");
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);
	const [hitCount, setHitCount] = useState(0);

	const [roleFilters, setRoleFilters] = useState({});
	const [badgeFilters, setBadgeFilters] = useState({});
	const [trackFilters, setTrackFilters] = useState({});
	
	const nav = useNavigate();

	const [showUserProfile, setShowUserProfile] = useState(null);
	
	const [users, setUsers] = useState([]);
	const [includeSubs, setIncludeSubs] = useState(false);

	const {terms} = useSelector((state) => state.app);


	const [selected, setSelected] = useState([]);
	const [stickHeader, setStickHeader] = useState(false);
	const [showExpando, setShowExpando] = useState(false);
	const [showSendEmail, setShowSendEmail] = useState(false);

	const search = function(pageNo) {
		var facets = [];
		var my_units = Object.keys(user.units).filter((key=>(user.units[key] & unitRoles.PERSONNEL_MGR) == unitRoles.PERSONNEL_MGR || (user.units[key] & unitRoles.COMMAND_MGR) == unitRoles.COMMAND_MGR)).map((key)=>key);

		if (selectedRoles.length) {
			facets.push(selectedRoles.map(u=>`assigned_roles:${u}`));
		}
		if (selectedBadges.length) {
			facets.push(selectedBadges.map(u=>`badges:${u}`));
		}
		if (selectedTracks.length) {
			facets.push(selectedTracks.map(u=>`tracks:${u}`));
		}

		if (unitRole) {
			facets.push(`unit_roles:${unitRole}`);
		}

		if (unit && !includeSubs) {
			facets.push(`unit_id:${unit.id}`);	
		} else if (unit) {
			facets.push(`parent_units:${unit.id}`);
		} else if (!unit) {
			if (my_units.length && !hasRole(user, roles.ADMIN)) {
				facets.push(my_units.map(u=>`parent_units:${u}`));
			}
		}

		if (verification) {
			if (verification == "pending") {
				facets.push(`pending_verification:1`);
			} else if (verification == "1") {
				facets.push(`verified:1`);
			} else if (verification == "0") {
				facets.push(`unverified:1`);
			}
		}
		
		index.search(name || '*', {
			attributesToRetrieve: ['uid', 'name', 'role_id','user_tags','affiliation_id', 'badges', 'tracks', 'track_info', 'agency_id', 'badges', 'track_progress', 'rank_id', 'photo', 'pending_verification', 'unit_name', 'unit_id', 'assigned_roles', 'verified_roles', 'unverified_roles', 'certs', 'unit_name', 'unit_location', 'unit_agency_id', 'cert_date', 'unverified', 'valk_role_id' ],
			responseFields: ['nbHits', 'nbPages', 'hits'],
			facetFilters: facets,
			facets: ['parent_units'],
			hitsPerPage: 20,
			page: page-1,
		}).then((resp ) => {
			var mapped = resp.hits.map((h)=> {
				return {
					...h,
					affiliation: affiliations.find(r=>r.id == h.affiliation_id)?.name,
					agency: agencies.find(r=>r.id == h.agency_id)?.name,
					agency_abbr: agencies.find(r=>r.id == h.agency_id)?.abbr,
					rank: ranks.find(r=>r.id == h.rank_id)?.name,
					role: roles.find(r=>r.id == h.valk_role_id)?.name
				}
			});
			setSelected([]);
			setUsers(mapped);
			setPages(resp.nbPages);
			setHitCount(resp.nbHits);

		}).catch((e)=>{
		});
	}

	useEffect(()=>{
		setPage(1);
		search();
	}, [name, unit, selectedRoles, unitRole, selectedBadges, selectedTracks, load, includeSubs, verification]);

	useEffect(()=>{
		search();		
	}, [page]);

	// useEffect(()=>{
	// 	if ((object_id && tab == "personnel") || user_id) {
	// 		setShowUserProfile(user_id || object_id);
		
	// 	}

	// }, [object_id, user_id]);

	const handleScroll = (e) => {
		var sticky = ctr.current.offsetTop;
		setStickHeader(window.scrollY > sticky) ;
	}

	useEffect(()=>{
		index.search('*', {
			facets: ["assigned_roles", "badges","tracks"],
			responseFields: ['facets']
		}).then((a,b,c)=>{
			setRoleFilters(a.facets.assigned_roles); 
			setBadgeFilters(a.facets.badges);
			setTrackFilters(a.facets.tracks);
		}).catch(err=>{
			debugger;
		});
		// search_client.searchForFacetValues({indexName: config.personnel_index, facetName: 'assigned_roles'}).then((resp)=>{
		// 	debugger;
		// }).catch(err=>{
		// 	debugger;
		// });
		
		// jax.get('/app/admin/personnel/tags').then((resp)=>{
		// 	setMyTags(resp.tags);
		// });
		// window.addEventListener('scroll', handleScroll, {passive: true});
		
		// return () => {
		// 	window.removeEventListener('scroll', handleScroll);
		// };
	}, []);

	const handleSelect = (user, isSelected) => {
		var sel = []
		if (isSelected) {
			sel = [...selected, user];
		} else {
			sel = selected.filter((x)=>x.uid != user.uid)
		}
		setSelected(sel);
		// headerRef.current.style["left"] = `${ctr.current.left}px`;
	}

	const profileUpdated = (profile) => {
		setUsers(users.map((u)=>u.uid == profile.uid ? {...u, ...profile} : u));
		search_client.clearCache();
	}

	useEffect(()=>{
		if ((object_id && tab == "personnel") || user_id) {
			setShowUserProfile(user_id || object_id);
		} else {
			setShowUserProfile(null);
		}
	}, [user_id, object_id]);

	const toggleRole = (id) => {
		if (!selectedRoles.includes(id.toString())) {
			setSelectedRoles([...selectedRoles, id.toString()]);
		} else {
			setSelectedRoles(selectedRoles.filter(x=>x != id.toString()));
		}
	}

	const toggleBadge = (id) => {
		if (!selectedBadges.includes(id.toString())) {
			setSelectedBadges([...selectedBadges, id.toString()]);
		} else {
			setSelectedBadges(selectedBadges.filter(x=>x != id.toString()));
		}
	}

	const toggleTrack = (id) => {
		if (!selectedTracks.includes(id.toString())) {
			setSelectedTracks([...selectedTracks, id.toString()]);
		} else {
			setSelectedTracks(selectedTracks.filter(x=>x != id.toString()));
		}
	}

	var ctr = useRef(null);
	var headerRef = useRef(null);
	var expRef = useRef(null);
	const theme = useTheme();

	return <Stack spacing={{xs:0, lg:0}} direction={{xs:"column", lg:"row"}}>
	
			<Box className="full-height" flex={1} sx={{mt: 0, whiteSpace:"nowrap"}} >
				<ProfileDialog onClose={()=>{nav('..', {relative: "path"})}} uid={showUserProfile} onUpdate={profileUpdated} onDeleteUser={(uid)=>{setUsers(users.filter(u=>u.uid != uid))}}></ProfileDialog>
				<SendMessage unit_id={unit?.id} user={user} selected={selected} show={showSendEmail} onClose={()=>setShowSendEmail(false)}></SendMessage>
				<Stack direction={{lg:"row", xs:"column"}} alignItems="stretch" justifyContent="space-between" >				
					{/* SIDEBAR */}
					<Box flex={1} ref={expRef} flexShrink={0} pb={1}>
						<Box pt={2} pb={1}><Typography variant="h6" sx={{fontWeight: 'normal', pl:1}}>Search</Typography></Box>
						<Divider/>
						<form onSubmit={(e) => {  }}>
							<Stack  p={0} pt={1} spacing={2}>

								{!!unit ? <FormControlLabel 
									label="Include Sub. Commands"  
									sx={{pr:1}}
									control={<Checkbox
										color="primary"
										checked={includeSubs}
										sx={{p:1}}
										onChange={(e)=>{setIncludeSubs(e.currentTarget.checked)}}/>}

								/> : <Box p={1} >
									<TextField fullWidth label="Name / Email" size="small" defaultValue={name} onChange={(e)=>setName(e.target.value)}></TextField>
								</Box>}
							
							</Stack>
							
							<Expando open={showExpando} onToggle={(open) => setShowExpando(open)} disableFor="lg" >
								<Stack spacing={0} px={0} mt={1}>
									
									{unit && <Box >
										<TextField fullWidth label="Search by Name" size="small" defaultValue={name} onChange={(e)=>setName(e.target.value)}></TextField>
									</Box>}

									{hasFeature(features.COMMAND_MANAGEMENT) && <Box px={1}>
										<FormControl size="small" fullWidth>  
											<InputLabel id="role-label">Command Role</InputLabel>
											<Select
												labelId="role-label"
												id="unitRole"
												value={unitRole}
												label="Command Role"
												onChange={(e) => setUnitRole(e.target.value)}
											>
												<MenuItem key="All" value="">(All)</MenuItem>
												{Object.keys(unitRoles).map((k) => {
													var role = unitRoles[k];
													var name = unitRoleNames[role];
													return <MenuItem key={role} value={role}>{name}</MenuItem>
												})}

											</Select>
										</FormControl>
									</Box>}
									<Stack direction="row" pt={2} pb={1} alignItems="center" justifyContent="space-between">
										<Box flex={1}><Typography variant="h6" sx={{fontWeight: 'normal', fontSize:"1.25rem", pl:1}}>Filters</Typography></Box>
										{!!selectedRoles?.length && <Box pr={1}><Link fontSize="0.8rem" onClick={()=>setSelectedRoles([])}>Clear All</Link></Box>}
									</Stack>
									
									<Accordion square disableGutters elevation={0} sx={{"&.Mui-expanded::before": {opacity:1}}}>
										<AccordionSummary expandIcon={<ExpandMore/>}>
											{terms["ROLE"].p}
										</AccordionSummary>
										<AccordionDetails sx={{p:0}}>
											<List dense>
												{roleFilters && Object.keys(roleFilters).map((k) => {
													let role = roleMap[k];
													let selected = selectedRoles.includes(role.id.toString());
													if (!role || role.base_role) return;
													return <ListItem key={role.id} disableGutters disablePadding 
														secondaryAction={
															<Chip size="small" variant={selected ? "filled" : "outlined"} color={selected ? "primary" : undefined} label={roleFilters[k]} sx={{mr: 2, fontSize:"0.75rem", height:"20px"}}/>
														} >
														<ListItemButton dense onClick={()=>toggleRole(role.id)} sx={{py:0, px:1}}>
															<ListItemIcon>
																<Checkbox  size="small" checked={selected}  />

															</ListItemIcon>
															<ListItemText primary={role.name} />
														</ListItemButton>
													</ListItem>
												})}
											</List>
										</AccordionDetails>
									</Accordion>
									<Accordion square disableGutters elevation={0} sx={{"&.Mui-expanded::before": {opacity:1}}}>
										<AccordionSummary expandIcon={<ExpandLess/>} >
											{terms["TRACK"].p}
										</AccordionSummary>
										<AccordionDetails sx={{p:0}}>
											<List dense>
												{trackFilters && Object.keys(trackFilters).map((k) => {
													let track = tracks.find(x=>x.id == k);
													let selected = selectedTracks.includes(track.id.toString());
													if (!track) return;
													return <ListItem key={k} disableGutters disablePadding 
														secondaryAction={
															<Chip size="small" variant={selected ? "filled" : "outlined"} color={selected ? "primary" : undefined} label={trackFilters[k]} sx={{mr: 2, fontSize:"0.75rem", height:"20px"}}/>
														} >
														<ListItemButton dense onClick={()=>toggleTrack(k)} sx={{py:0, px:1}}>
															<ListItemIcon sx={{minWidth: 'unset !important'}}>
																<Checkbox size="small" checked={selected}  />
															</ListItemIcon>
															<ListItemIcon sx={{minWidth: 'unset !important', marginRight: 1}}>
																<TrackIcon color={track.color} size={16} progress={65}/>
															</ListItemIcon>
															<ListItemText primary={track.name} />
														</ListItemButton>
													</ListItem>
												})}
											</List>
										</AccordionDetails>
								
									</Accordion>
									<Accordion square disableGutters elevation={0} sx={{"&.Mui-expanded::before": {opacity:1}}}>
										<AccordionSummary expandIcon={<ExpandLess/>}>
											{terms["BADGE"].p}
										</AccordionSummary>
										<AccordionDetails sx={{p:0}}>
											<Box p={1} px={2} mt={1}>
												<Grid2 container spacing={2}>
													{badges.map((b,i) =>{
														var selected =  selectedBadges.includes(b.id.toString());
														return <Grid2 xs={3} key={i}>
															<Badge badgeContent={badgeFilters[b.id]} color={selected ? "primary" : "white"} sx={{'& .MuiBadge-badge': {border: `solid 1px ${selected ? 'transparent' : '#ccc'}`}}}>
																<ToggleButton value="check" selected={selected} onChange={(e)=>{toggleBadge(b.id)}}>
																	<BadgeIcon color={b.color} icon={b.icon} tooltip={b.name} size="medium"></BadgeIcon>
																</ToggleButton>
															</Badge>
															
														</Grid2>
													})}
												</Grid2>
											</Box>
										</AccordionDetails>
									</Accordion>									

									<Divider my={0}></Divider>
									{false && <Box>
										<FormControl size="small"  sx={{ minWidth: 200 }} fullWidth>  
											<InputLabel id="verification-label">Verification</InputLabel>
											<Select
												labelId="verification-label"
												id="verification"
												value={verification}
												label="Verification"
												onChange={(e) => setVerification(e.target.value)}
											>
												<MenuItem value="">(All)</MenuItem>
												<MenuItem value="1">Verified</MenuItem>
												<MenuItem value="0">Unverified</MenuItem>
												<MenuItem value="pending">Pending Verification</MenuItem>
											</Select>
										</FormControl>
									</Box>}

								</Stack>
							</Expando>
						</form>
					</Box>
					

					<Divider orientation="vertical" flexItem></Divider>
					{/* PERSONNEL LIST */}
					<Box flex={3}  ref={ctr} sx={{position:"relative", overFlowX:'auto'}}>
						{/* <Box className={`overlay ${showExpando && "shown"}`} onClick={(e)=>setShowExpando(false)}></Box> */}
						<Card elevation={stickHeader ? 1 : 0}  sx={{borderRadius: 0}} >
							<Stack direction="row" justifyContent="space-between" p={1} pt={2} pr={2}  alignItems="center" spacing={{xs:0, lg:1}}>
								<Checkbox
									color="primary"
									mr={0}
									sx={{padding:0.5}}
									disabled={hitCount == 0}
									onChange={(e)=>{e.stopPropagation(); setSelected(e.currentTarget.checked ? users.map((u)=>u) : [])}}
								/>
							
								{!!selected.length && !!hitCount && <Box flex={1} sx={{fontWeight: 'bold'}}>{selected.length} of {hitCount} record{hitCount != 1 && 's'} selected</Box>}
								{!selected.length && !!hitCount && <Box flex={1}>{hitCount} record{hitCount != 1 && 's'} found</Box>}
								<Box flex={1}></Box>
								
								<Button size="small" variant="contained" onClick={()=>setShowSendEmail(!showSendEmail)} disabled={!selected?.length} startIcon={<Email/>}>Send Message</Button>
								
							</Stack>
							<Divider></Divider>
						</Card>
						<List  flex={1}>
							
							{users?.map((usr, i) => {
								return <Box key={usr.uid}>
									<UserRow user={usr} onSelect={handleSelect} selected={!!selected.find(x=>x.uid == usr.uid) } onClick={(uid)=>nav(uid)} ></UserRow>
									{i < users.length-1 && <Divider></Divider>}
								</Box>;
							})}
						</List>
						{!users.length && <Box p={2} py={6} textAlign="center" whiteSpace="normal"><i>No personnel matched the specified criteria.  If searching by name, please enter three or more letters.</i></Box>}
						{pages > 1 && <>
							<Divider flex={1}></Divider>
							<Stack  alignItems="center">
								<Box p={2} >
									<Pagination count={pages} page={page} onChange={(e, p)=>setPage(p)}></Pagination>
								</Box>
							</Stack>
						</>}
				
					</Box>
				</Stack>

				
				
			</Box>
		
	</Stack>
}

const SmallRoleChip = styled(Chip)(({ theme }) => ({
	  borderRadius: "6px",
	  marginRight: 0.5,
	  marginTop: 1,
	  marginBottom: 1,
	  fontWeight: 500,
	  fontSize: '0.7em',
	  height: 22,
	  opacity: 0.8,
	  '& .MuiSvgIcon-root': {
		fontSize: '1rem'
	  },
	  '&.hasMore': {
		marginRight: 1,
		borderRadius: "6px 0 0 6px",
	  },
	  '&.more': {
		opacity: 0.7,
		borderRadius: "0 6px 6px 0",
		'& span' : {
			padding: '0 6px'
		},
		'&:hover': {
			opacity: 1,
			'&+div': {
				
				'& .moreRoles': {
					transform: 'translateX(0)',
					transition: 'transform 0.25s ease-in-out'
				}
			}
		}
	  }
}));

const MoreRolesContainer = styled(Box)(({ theme }) => ({
	position: "absolute",
	top: 0,
	overflowX: 'hidden',
	pointerEvents: 'none',
	'& .moreRoles': {
		transform: 'translateX(-100%)',
		pointerEvents: 'auto',
		transition: 'transform 0.25s ease-in-out'	
	},
	'&:hover .moreRoles': {
		transform: 'translateX(0)',
		transition: 'transform 0.25s ease-in-out'
	}
}));

export function UserRoles(props) {
	const {user, size} = props;
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
	const [user_roles, setUserRoles] = useState([]);
	const theme = useTheme();
	const roles = useSelector((state) => state.data.roles);	
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(()=>{
		var rs = user.assigned_roles?.map((r,i)=>{
			if (!r) return;
			var role = roles.find(x=>x.id == r);
			if (!role) {
				return null;
			}
			{return {
				...role,
				verified: user.verified_roles?.includes(r),
				unverified: user.unverified_roles?.includes(r),
			}}
		}).filter(x=>!!x).sort((a,b)=>{
			var av = a.verified ? -1 : (a.unverified ? 1 : 0);
			var bv = b.verified ? -1 : (b.unverified ? 1 : 0);
			return av - bv;
		});
		setUserRoles(rs);

	},[user]);

	return <Stack flexWrap="wrap" direction="row"  alignItems="center" flex={4} flexGrow={false} flexShrink={false} sx={{overflow:"hidden", textOverflow:"ellipsis"}} py={isMobile ? 0.5 : 0} pl={0} >
		
		{!!user_roles?.length && <RoleChip className={user_roles?.length > 1 ? 'hasMore' : ''} size={size || "medium"} role={user_roles[0]}></RoleChip>}
		
		{user_roles?.length > 1 && <Box position="relative">

			<SmallRoleChip className="more" onClick={(e)=>setAnchorEl(e.currentTarget)} size={size || "medium"} sx={{opacity: 1, fontWeight: 500, marginRight:0.5, marginTop: 1, marginBottom: 1, borderRadius: "6px", color: user_roles[1].unverified ? theme.palette.disabled.main : undefined, '&:hover': {opacity:0, transition: 'opacity 0.25s'}}} variant={user_roles[1].unverified ? "outlined" : "filled"} color={!user_roles[1].unverified ? "primary" : undefined}   label={`+${user_roles.length-1}`}></SmallRoleChip>

			<Notification open={!!anchorEl} anchorEl={anchorEl} placement="top-end" transition over={true} ContainerProps={{py:0, px: 1}} onClose={()=>setAnchorEl(null)}  >
				<Box >
					{user_roles?.filter((x,i)=>i>0).map((r,i)=>{
						let el = <RoleChip key={i} size={size || "medium"} role={r}></RoleChip>;
						var chip = r.unverified ? <Tooltip key={i} arrow placement="top" title="There is no user certification to verify this role">{el}</Tooltip> : el;
						
							return chip;
						
					})}
				</Box>	
			</Notification>
		</Box>} 
		
	</Stack>
}

const RoleChip = function(props) {
	const {role, size, ...other} = props;
	const theme = useTheme();
	let el = <SmallRoleChip {...other} size={size || "medium"} sx={{fontWeight: 500, marginRight:0.5, marginTop: 1, marginBottom: 1, background: role.unverified ? 'white' : '', borderRadius: "6px", color: role.unverified ? theme.palette.disabled.main : undefined}} variant={role.unverified ? "outlined" : "filled"} color={!role.unverified ? "primary" : undefined} onDelete={role.unverified ? ()=>{return false;} : undefined} deleteIcon={role.unverified ? <HelpOutline/> : undefined} label={role.name} icon={role.verified ? <VerifiedOutlined/> : undefined}></SmallRoleChip>;
	var chip = role.unverified ? <Tooltip arrow placement="top" title="There is no user certification to verify this role">{el}</Tooltip> : el;
	return chip;
}

