import { AccessTime, Add, AddCircleOutline, AddOutlined, AddTask, AssignmentTurnedIn, Beenhere, Check, Clear, Delete, DoneAll, HowToReg, Medication, MilitaryTech, Save, School, Star, StarHalf, TaskAlt, Update, WhereToVote } from '@mui/icons-material';
import { Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9 } from '@mui/icons-material';
import { Badge, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, lighten, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Box, IconButton, Stack, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm, set } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ColorInput from '../components/ColorInput';
import jax from '../helper/jax';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Paper } from '@mui/material';
import { Divider } from '@mui/material';
import Notification from '../components/Notification';
import { setBadges as setBadgeData, setTracks as setTrackData} from '../features/data';
import { useTheme } from '@emotion/react';

const StyledIcon = styled(IconButton)((props:any) => ({
	
	padding: 2,
	'&.new': {
		border: 'solid 2px #ccc',
	},
	'& svg': {
		height: '1.5rem',
		width: '1.5rem',
	},
	'&:not(.new)': {
		cursor: props.onClick ? 'pointer' : 'auto',
		backgroundColor: props.color,
		backgroundImage: props.shade && 'linear-gradient(235deg, #FF880088, #00000000)',
		color: 'white',
	},
	'&.MuiIconButton-sizeLarge svg': {
		height: '1.75rem',
		width: '1.75rem',
		padding: '6px'
	},
	'&.MuiIconButton-sizeSmall svg': {
		height: '1rem',
		width: '1rem',
		padding: '4px'
	}

	
})); 



const BadgeIcons = {
	Check : <Check/>,
	DoneAll: <DoneAll/>,
	TaskAlt: <TaskAlt/>,
	AddTask: <AddTask/>,
	StarHalf: <StarHalf/>,
	Star: <Star/>,
	Add: <Add/>,
	AddCircleOutline: <AddCircleOutline/>,
	AssignmentTurnedIn: <AssignmentTurnedIn/>,
	School: <School/>,
	HowToReg: <HowToReg/>,
	WhereToVote: <WhereToVote/>,
	
	AccessTime: <AccessTime/>,
	Update: <Update/>,
	
	Medication: <Medication/>,
	MilitaryTech: <MilitaryTech/>,
	Filter1: <Filter1/>,
	Filter2: <Filter2/>,
	Filter3: <Filter3/>,
	Filter4: <Filter4/>,
	Filter5: <Filter5/>,
	Filter6: <Filter6/>,
	Filter7: <Filter7/>,
	Filter8: <Filter8/>,
	Filter9: <Filter9/>
}

const BadgeIcon = (props: any) => {
	const {color, tooltip, icon, isNew, shade, ...other} = props;
	var icn = <StyledIcon {...other} shade={shade !== false} color={color} className={isNew ?"new" : ""}>
		{isNew ? <Add/> : BadgeIcons[icon] || props.children || <Star/>}
	</StyledIcon>;

	if (tooltip) {
		return <Tooltip title={tooltip}>{icn}</Tooltip>
	}
	return icn;
}

const Tracks: React.FC = () => {

	const {terms} = useSelector((state: any) => state.app); 
	const [editBadge, setEditBadge] = React.useState<any>(null);
	const [editTrack, setEditTrack] = React.useState<any>(null);
	const [badges, setBadges] = React.useState<any>([]);
	const [tracks, setTracks] = React.useState<any>([]);
	
	const dispatch = useDispatch();
	

	useEffect(() => {
		jax.get('/app/admin/badges').then((data) => {	
			setBadges(data.badges);
			setTracks(data.tracks);
		});
	}, []);

	const saveBadge = (badge: any) => {
		jax.post('/app/admin/badges', badge).then((data) => {
			//Update or insert the badge by id in the badges array
			let idx = badges.findIndex((b:any) => b.id === data.id);
			if (idx >= 0) {
				badges[idx] = data;
			} else {
				badges.push(data);
			}
			setBadges([...badges]);
			dispatch(setBadgeData(badges));
		});
	}

	const saveTrack = (track: any) => {
		jax.post('/app/admin/tracks', track).then((data) => {
			//Update or insert the badge by id in the badges array
			let idx = tracks.findIndex((b:any) => b.id === data.id);
			if (idx >= 0) {
				tracks[idx] = data;
			} else {
				tracks.push(data);
			}
			setTracks([...tracks]);
			dispatch(setTrackData(tracks));
		});
	}

	return <>
		<Box p={2}>
			{/* <h1>{terms["BADGE"].p} &amp; {terms["TRACK"].p}</h1> */}
			<Divider sx={{mt:3, mb:2}}><b>{terms["BADGE"].p}</b></Divider>
			
			<Box mt={1}>{terms["BADGE"].p} are useful in tracking milestones or accomplishments for {terms["USER"].p.toLowerCase()} through the course of their training.  They can be used to restrict enrollment in specialized courses based on pre-requisite completion.</Box>
			
			<Grid2 container spacing={2} mt={2}>
				{badges.map((b,i) =>{
					return <Grid2 xs={2} md={2} lg={1} key={i}>
						<Stack spacing={1} alignItems="center" flex={1}>
							<BadgeIcon size="large" color={b.color} tooltip={b.description} icon={b.icon} onClick={()=>setEditBadge(b)}><Star/></BadgeIcon>
							<Box textAlign="center" className="sub-title xs">{b.name}</Box>
						</Stack>
					</Grid2>
				})}
				<Grid2 xs={2} md={2} lg={1} >
					<Stack spacing={1} alignItems="center" flex={1}>
						<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={()=>setEditBadge({name: 'New Badge', icon: "Check"})}></BadgeIcon>
						<Box textAlign="center" className="sub-title xs">New {terms["BADGE"].s}</Box>
					</Stack>
				</Grid2>
			</Grid2>

			{/* <Stack direction="row" spacing={1} mt={2} justifyContent="flex-start" flexBasis={12}>
				{badges.map((b,i) =>{
					return <Stack spacing={1} alignItems="center" flex={1}>
						
						<BadgeIcon size="large" color={b.color} tooltip={b.description} onClick={()=>setEditBadge(b)}><Star/></BadgeIcon>
						<div className="sub-title xs">{b.name}</div>
						
					</Stack>;
				})}
				<Stack spacing={1} alignItems="center" flex={1}>
					<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={()=>setEditBadge({name: 'New Badge'})}></BadgeIcon>
					<div className="sub-title xs">New {terms["BADGE"].s}</div>
				</Stack>
			</Stack> */}
			<Divider sx={{mt:3, mb:2}}><b>{terms["TRACK"].p}</b></Divider>
			
			<Box mt={1}>{terms["TRACK"].p} are a series of {terms["BADGE"].p.toLowerCase()} that a indicate {terms["USER"].s.toLowerCase()} progress toward a specific goal.  When a {terms["USER"].s.toLowerCase()} possesses all {terms["BADGE"].p.toLowerCase()} in a {terms["TRACK"].s.toLowerCase()}, it is considered complete.  New {terms["CERTIFICATION"].p} and / or {terms["ROLE"].p.toLowerCase()} can be automatically assigned when a track has been completed.</Box>
			
			<Stack spacing={1} alignItems="center" flex={1} mt={2} >
				<Grid2 container alignItems="stretch" justifyContent="stretch" spacing={2}>
					{tracks.map((t,i) => {	
						return <Grid2 lg={6} xs={12} key={i}>
							<Paper variant="outlined" sx={{height:'100%'}}>
								<List dense sx={{height:"100%"}}>
									
									<ListItem  sx={{p:0, height:"100%"}}>
										<ListItemButton sx={{height:"100%"}} onClick={()=>setEditTrack(t)}>
											<Stack direction="row" spacing={2} flex={1} alignItems="center" justifyContent="stretch" sx={{height:"100%"}}>
												<Stack direction="row" spacing={2} alignItems="center" flex={1} flexShrink={0}>
													<ListItemAvatar sx={{flex:0, minWidth:'unset'}}>
														<TrackIcon size="48px" color={t.color} tooltip={t.description} progress={65}/>
													</ListItemAvatar>
													<ListItemText  primary={t.name} secondary={t.description} primaryTypographyProps={{fontSize:"1rem", fontWeight: "bold", flex:1}}>
													</ListItemText>
												</Stack>
												<Stack direction="row"  spacing={-1}>
													{t.badges?.map((b, j) => {
														var bg= badges.find((badge:any) => badge.id === b);
														return <BadgeIcon size="large" color={bg.color} icon={bg.icon} tooltip={bg.name} sx={{border:'solid 1px white'}}/>
													})}
												</Stack>
											</Stack>
										</ListItemButton>
									</ListItem>
									
								</List>
							</Paper>
						</Grid2>
					})}
					<Grid2 lg={6} xs={12} spacing={2} sx={{flex:1}}>
						<Paper variant="outlined" sx={{height:'100%'}}>
							<List dense sx={{height:"100%"}}>
							<ListItem  sx={{p:0,height:"100%"}}>
									<ListItemButton sx={{height:"100%", opacity:0.6, "&:hover":{opacity:1}}} onClick={()=>setEditTrack({name: 'New Track'})}>
										<Stack direction="row" height="100%" spacing={2} flex={1} alignItems="center" justifyContent="stretch">
											<Stack direction="row" spacing={2} alignItems="center" flex={1} py={3} flexShrink={0}>
												<ListItemAvatar sx={{flex:0, minWidth:'unset'}}>
													<TrackIcon size="48px" color={"#666666"}  progress={65}/>
												</ListItemAvatar>
												<ListItemText  primary={`Create a new ${terms["TRACK"].s}`} primaryTypographyProps={{fontSize:"1rem", fontWeight: "bold", flex:1}}>
												</ListItemText>
											</Stack>
											<Stack direction="row"  spacing={-1}>
												
													<BadgeIcon size="large" shade={false} color={"#666"}  sx={{border:'solid 1px white'}}/>
												
											</Stack>
										</Stack>
									</ListItemButton>
								</ListItem>
							</List>
						</Paper>
					</Grid2>
				</Grid2>
			</Stack>
		</Box>

		<BadgeDialog badge={editBadge} open={editBadge != null} onSave={saveBadge} onClose={()=>setEditBadge(null)}/>
		<TrackDialog track={editTrack} open={editTrack != null} onSave={saveTrack} onClose={()=>setEditTrack(null)}/>
	</>;
};

const BadgeDialog: React.FC = (props: any) => {
	const {badge, open, onSave, onClose} = props;

	const {handleSubmit, control, watch, reset, setValue} = useForm({
		defaultValues: {name: '', description: '', icon: 'Star', color: '#FF0000', ...badge}
	});
	const theme = useTheme();
	
	const {terms} = useSelector((state: any) => state.app);
	useEffect(() => {
		if (badge) {
			reset(badge);
		}
	}, [badge]);

	return <Dialog  fullWidth maxWidth="xs"
		open={open} 
		onClose={onClose}
		PaperProps={{
			component: 'form',
			onSubmit: handleSubmit((data)=>{
				onSave(data);
				onClose();	
			})
		  }}>
		<Box className="card-header" sx={{padding:1, font:""}}>
		  {badge?.id ? `Edit ${terms["BADGE"].s}` : `Create a New ${terms["BADGE"].s}`}
		</Box>
		<DialogContent dividers sx={{padding:0}}>
		<Stack p={2} spacing={2}>
			
			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="name" render={({field}) => <TextField {...field} label={`${terms["BADGE"].s} Name`} size="small"/>} /> 
			</FormControl>

			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="description" render={({field}) => <TextField multiline rows={2} {...field} label="Description" size="small"/>} /> 
			</FormControl>
			
			<Divider>Color and Icon</Divider>
			<Stack direction="row" spacing={2} alignItems="stretch">
				
				<Box flex={1} height="200px" pr={1} sx={{overflowY:'auto', overflowX:'hidden'}} borderRadius={1} p={0.5} border={`solid 1px ${theme.palette.divider}`}>
					<FormControl fullWidth size="small" variant='outlined'>
						
						<Controller control={control} name="icon" render={({field}) => {
							return <ToggleButtonGroup size="small" exclusive {...field} onChange={(e,v)=>setValue("icon", v)}>
								<Grid2 container spacing={1}>
									{Object.keys(BadgeIcons).map((icon, i) => {
										return <Grid2 xs={3}>
											<ToggleButton size="small" value={icon} selected={field.value == icon}>{BadgeIcons[icon]}</ToggleButton>
										</Grid2>
									})}
									
								</Grid2>
							</ToggleButtonGroup>
						}} />
					</FormControl>
				</Box>
				<Stack flex={1}>
					<FormControl fullWidth size="small" >
						<Controller control={control} name="color" render={({field}) => <ColorInput {...field} value={watch('color')} label="Color" size="small"/>} />
					</FormControl>
					<Stack flex={1} alignItems="center" justifyContent="center">
						<BadgeIcon size="large" color={watch('color')} icon={watch('icon')} onClick={()=>setValue("color", "#000000")}></BadgeIcon>
					</Stack>
				</Stack>
			</Stack>

		</Stack>
		</DialogContent>
		<DialogActions>
			<Stack direction="row" spacing={2} flex={1} justifyContent="space-between" >
				<Button size="small"  onClick={onClose}>Cancel</Button>
				<Button type="submit" size="small" variant="contained">Save</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

const TrackDialog: React.FC = (props: any) => {
	const {track, open, onSave, onClose} = props;

	const {badges} = useSelector((state: any) => state.data);
	const {handleSubmit, control, watch, reset, setValue} = useForm({
		defaultValues: {name: '', description: '', color: '#0000FF', ...track}
	});
	const theme = useTheme();
	
	const {terms} = useSelector((state: any) => state.app);

	const [addBadgeEl, setAddBadgeEl] = React.useState<any>(null);
	useEffect(() => {
		if (track) {
			reset(track);
		}
	}, [track]);
 
	return <Dialog  fullWidth maxWidth="xs"
		open={open} 
		onClose={onClose}
		PaperProps={{
			component: 'form',
			onSubmit: handleSubmit((data)=>{
				onSave(data);
				onClose();	
			})
		  }}>
		<Box className="card-header" sx={{padding:1, font:""}}>
		  {track?.id ? `Edit ${terms["TRACK"].s}` : `Create a New ${terms["TRACK"].s}`}
		</Box>
		<DialogContent dividers sx={{padding:0}}>
		<Stack p={2} spacing={2}>
			
			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="name" render={({field}) => <TextField {...field} label={`${terms["BADGE"].s} Name`} size="small"/>} /> 
			</FormControl>

			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="description" render={({field}) => <TextField multiline rows={2} {...field} label="Description" size="small"/>} /> 
			</FormControl>
			
			
			<Stack direction="row" spacing={2} alignItems="center">
				
				<Box flex={1}>
					<FormControl fullWidth size="small" >
						<Controller control={control} name="color" render={({field}) => <ColorInput {...field} value={watch('color')} label="Color" size="small"/>} />
					</FormControl>
				</Box>
				<Box pr={1} flex={1}>
					<TrackIcon size="32px" color={watch('color')} progress={65}/>
				</Box>
				
			</Stack>

		  	<Divider>Track {terms["BADGE"].p}</Divider>
			
			{track && <Grid2 container spacing={2} mt={2}>
				{watch('badges')?.map((b,i) =>{
					var badge = badges.find((badge:any) => badge.id === b);
					return <Grid2 xs={2} md={4} lg={4} key={i}>
						<Box>
							<Stack spacing={1} alignItems="center" flex={1}>
								<Badge sx={{"& .MuiBadge-badge":{px:0, cursor:'pointer'}}} badgeContent={<Clear sx={{padding:0, fontSize:'1rem'}}/>} color="error" onClick={()=>{setValue("badges", watch('badges').filter((id:any)=>id !== b))}}>
									<BadgeIcon size="large" color={badge.color} tooltip={badge.description} icon={badge.icon} ><Star/></BadgeIcon>
								</Badge>
								<Box textAlign="center" className="sub-title xs">{badge.name}</Box>
							</Stack>
						</Box>
					</Grid2>
				})}
				<Grid2 xs={2} md={4} lg={4} >
					<Stack spacing={1} alignItems="center" flex={1}>
						<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={(e)=>setAddBadgeEl(e.currentTarget)}></BadgeIcon>
						<Box textAlign="center" className="sub-title xs">Add {terms["BADGE"].s}</Box>
					</Stack>
				</Grid2>
			</Grid2>}
			<BadgeMenu badgeIds={badges.map(b=>b.id)} el={addBadgeEl} onClose={()=>setAddBadgeEl(null)} onSelect={(id: any)=>{setValue("badges", [...watch('badges'), id]); setAddBadgeEl(null)}}/>
		</Stack>
		</DialogContent>
		<DialogActions>
			<Stack direction="row" spacing={2} flex={1} justifyContent="space-between" >
				<Button size="small"  onClick={onClose}>Cancel</Button>
				<Button type="submit" size="small" variant="contained">Save</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

const TrackIcon = (props: any) => {
	const {color, tooltip, progress, complete, size, ...other} = props;
	var icon = <Box position="relative" height={size || 28} width={size || 28}>
		<CircularProgress variant="determinate" size={size || 28} value={100} thickness={8} sx={{
			color: (theme) => color,
			opacity: 0.25,
			}} />
		<CircularProgress variant="determinate" size={size || 28} value={progress || 10} thickness={8} sx={{
			position: 'absolute',
			left:0,
			color: (theme) => color,
			}} />
		{complete && <Box position="absolute" top={0} left={0} width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
			<Check sx={{color: color, fontSize: '14px'}}/>
		</Box>}
	</Box>;

	if (tooltip) {
		return <Tooltip title={tooltip}>{icon}</Tooltip>
	}
	return icon;

}
const BadgeMenu = (props: any) => {
	const {badgeIds, el, onSelect, onClose} = props;

	const {badges : allBadges} = useSelector((state: any) => state.data);

	const [badges, setBadges] = React.useState<any>([]);
	useEffect(() => {
		if (badgeIds) {
			setBadges(allBadges.filter((b:any) => badgeIds.includes(b.id)));
		} else {
			setBadges([]);
		}
	}, [badgeIds, allBadges]);
	return <Notification over={true} anchorEl={el} open={!!el} ContainerProps={{p:0, maxWidth:300, maxHeight: 200, overflow:'auto'}} onClose={onClose}>
			<List dense sx={{p:0}}>
				{badges?.map((b,i) =>{
					return <ListItemButton key={i} onClick={()=>{onSelect(b.id); onClose()}} sx={{py:0, alignItems:'flex-start'}}>
						<ListItemAvatar sx={{minWidth:'unset', mr:1, mt:1}}>
							<BadgeIcon size="small" icon={b.icon} color={b.color} ></BadgeIcon>
						</ListItemAvatar>
							<ListItemText primary={b.name} secondary={b.description} secondaryTypographyProps={{className:"sub-title xs", textOverflow:"ellipsis", overflow:'hidden', sx: {
								display: "-webkit-box",
								textOverflow: "ellipsis",
								overflow: "hidden",
								WebkitLineClamp: "2",
								WebkitBoxOrient: "vertical"
							} }}/>
							
						</ListItemButton>
					
				})}
			</List>
		</Notification>
			};

export default Tracks;
export {BadgeIcon, TrackIcon, BadgeMenu};

