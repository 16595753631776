import { Dialog, Skeleton, Tabs, Tab, Divider, useMediaQuery, Link, DialogActions, Button, Alert, AlertTitle, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import jax from "../helper/jax";
import { Avatar } from "@mui/material";
import { Box } from "@mui/system";
import { DialogContent } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";


import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
// import { roleNames, roles } from "../features/access";
import { asDate } from "../helper/util";
import { features, hasRole } from "../features/access";
import { Delete, Lock, Verified } from "@mui/icons-material";
import Notification from "./Notification";	
import { useTheme } from "@emotion/react";
import AgencyAvatar from "./AgencyAvatar.tsx";
import TrainingHistory from "./TrainingHistory";
import { hasFeature } from "../store.js";
import { UserRoles } from "../admin/Personnel.js";
import { BadgeIcon, TrackIcon } from "../admin/Tracks.tsx";


export default function ProfileDialog(props) {
	const {uid, onClose, onUpdate, onDeleteUser} = props;
	const [user, setUser] = useState({});
	const [training, setTraining] = useState([]);
	
	const [unitRoles, setUnitRoles] = useState([]);
	const [assignedAgency, setAssignedAgency] = useState(null);
	const admin = useSelector((state) => state.data.user);
	const [confirmDelete, setConfirmDelete] = useState(null);
	const {roles, roleMap} = useSelector(state=>state.data);
	const [certs, setCerts] = useState([]);
	const {tracks, badges} = useSelector(state=>state.data);
	const [tab, setTab] = useState("PROFILE");
	
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const {agencies} = useSelector((state) => state.data);
	const theme = useTheme();
	const {terms} = useSelector(state=>state.app);

	useEffect(()=>{
		setUser(null);
		setTraining([]);
		
		if (!!uid && uid != user?.uid) {
			jax.get(`/app/profile/${uid}`).then((response)=>{
				setUser({...response.profile, tracks: response.tracks, badges: response.badges});
				setTraining(response.training);
				setCerts(response.certs);
				setUnitRoles(response.unit_roles);
				var agency = agencies.find((a)=>a.id == response.profile.unit_agency_id);
				setAssignedAgency(agency);
			});
		}
	}, [uid]);

	function deleteUser(uid) {
		setConfirmDelete(null);
		jax.delete(`/app/admin/personnel/${uid}`).then(()=>{
			!!onDeleteUser && onDeleteUser(uid);
			onClose();
		});
	}

	function updateTraining(t,profile) {
		setTraining(t);
		onUpdate(profile);
	}

	return <Dialog open={!!uid} onClose={onClose}  fullWidth maxWidth="md"  PaperProps={{sx:{minHeight: 400}}} keepMounted={false}>
		<DialogTitle sx={{p:0, px:2}}>
			<Stack direction="row" alignItems="center" flex={1} pt={2}>
				<AgencyAvatar agency_id={user?.agency_id} size={48}/>
				<Box ml={1} >
					<Typography variant={isMobile ? "h5" : "h4"}>{user ? `${user.title}` : <Skeleton/>}</Typography>
					<Typography  style={{fontWeight: 'normal', margin:"0", lineHeight:"1.4rem"}}>{user?.rank}</Typography>
				</Box>
			</Stack>
			<Tabs value={tab} onChange={(e,v)=>setTab(v)}>
				<Tab label="Profile" value="PROFILE" />
				<Tab label="Training" value="TRAINING" />
				{hasFeature(features.COMMAND_MANAGEMENT) && <Tab label="Unit" value={2} onClick={()=>setTab("UNIT")}/>}
			</Tabs>
		</DialogTitle>
		<DialogContent sx={{padding: 0}} dividers scroll="paper">
			<Box p={0}>
				
				{user && <Box mt={0}> 
					{tab == "PROFILE" && <Box p={0}>
							<Stack py={2} direction={{xs:"column", lg:"row"}} spacing={{xs: 3, lg: 3}} p={2}>
								<Box flex={0.5}>
									<Box sx={{paddingTop:"80%", position:"relative"}} >
										<Box sx={{position:"absolute", top:0, left:0, right:0, bottom:0, display:"block", alignItems:"center", justifyContent:"center"}}>
											{user ? <Avatar variant="rounded" src={user.photo} sx={{border:"solid 2px white",  width:"80%", height:"100%" }} /> : <Skeleton variant="rounded" sx={{ width:"80%", height:"100%", maxWidth: "none"}}><Avatar sx={{width:"80%", height:"100%"}}></Avatar></Skeleton>}
										</Box>
									</Box>
								</Box>
								
								<Stack spacing={2} flex={1}>
									<div>
										<div className="sub-title">
											Title
										</div>
										<Box mt={1}>
											{user.title}
										</Box>
									</div>
									{hasFeature(features.MILITARY_PROFILES) && !!user.affiliation && <div>
										<div className="sub-title">
											Affliation
										</div>
										<Box mt={1}>
											{user.affiliation} {user.agency}
											{!user.agency && <i>---</i>}
										</Box>
									</div>}
									{hasFeature(features.COMMAND_MANAGEMENT) && <div>
										<div className="sub-title">
											Current Assigned Unit
										</div>
										{user.unit_id ? <Box mt={1}>
											<Stack direction="row" alignItems="center">
												<img style={{height: '20px', width: '20px', verticalAlign: 'middle', display:'inline', marginRight:8}} src={`/images/agency/${assignedAgency.abbr}.png`}/>
												<RouterLink className="primary" to={`/commands/${user.unit_id}`}>{user.unit_name}</RouterLink>
											</Stack>
										</Box> : <div>
											<i>None</i>
										</div>}
									</div>}
									<div>
										<div className="sub-title">
											Role(s)
										</div>
										<Box mt={1}>
											<UserRoles user={user}></UserRoles>
										</Box>
									</div>
									<div>
										<div className="sub-title">
											Contact Phone #
										</div>
										<Box mt={1}>
											{user.phone || <i>None</i>}
										</Box>
									</div>
									<div>
										<div className="sub-title">
											Contact Email
										</div>
										<Box mt={1}>
											{user.email ?  <Link  href={`mailto:${user.email}`}>{user.email}</Link> : <i>None</i>}
										</Box>
									</div>
								</Stack>

								<Stack flex={1} spacing={1}>
									<h4>Certifications</h4>
									<Divider/>
									<Stack spacing={1}>
										{!certs?.length && <Box className="sub-title"><i>This user holds no current certifications.</i></Box>}
										{certs?.map((c, i)=>{
											return <Alert severity="success" color="disabled" icon={<Verified/>}>
												<AlertTitle>{c.name}</AlertTitle>
												{c.cert_no && <div>#{c.cert_no}</div>}
												<Box>{asDate(c.cert_date)}</Box>
											</Alert>
										})}
										<Box></Box>
									</Stack>
								</Stack>

								<Stack flex={1} spacing={1}>
									<h4>{terms["TRACK"].p}</h4>
									<Divider/>
									{user.tracks?.map((t, i)=>{
										var track = tracks.find(x=>x.id == t.track_id);
										return track && <Stack key={i} direction="row" alignItems="center" spacing={1}>
											<Box><TrackIcon color={track.color} complete={!!t.date_completed} tooltip={`${t.held} of ${t.total} ${terms["BADGE"].p} completed`} progress={t.held/t.total*100}/> </Box>
											<Stack>
												<Box>{track?.name}</Box>
												{!!t.date_completed && <Box className="sub-title xs">Completed {asDate(t.date_completed)}</Box>}
												{!t.date_completed && <Box className="sub-title xs">{t.held} / {t.total} {terms["BADGE"].p} </Box>}
											</Stack>
										</Stack>;

									})}
								</Stack>
							</Stack>
							<Divider variant="middle" sx={{mt:2}}>Earned {terms["BADGE"].p}</Divider>
							<Stack direction="row" spacing={2} p={2} px={4}>
								{user.badges?.map((b, i)=>{
									var badge = badges.find(x=>x.id == b.badge_id);
									return badge && <Stack spacing={1} key={i} alignItems="center">
										<BadgeIcon size="large" icon={badge.icon} color={badge.color} tooltip={badge.description} /> 
										<Box className="sub-title xs">{badge.name}</Box>
									</Stack>
								})}

								{!user.badges?.length && <Box className="sub-title"><i>This user holds no {terms["BADGE"].p.toLowerCase()}.</i></Box>}
							</Stack>
						</Box>}
						
						{/* <FlexTab label="Certifications">
							Cerifications
						</FlexTab>  */}
						
						{tab == "TRAINING" && <Box>
							<TrainingHistory records={training} user_badges={user.badges} user_tracks={user.tracks} user_id={user.uid} onTrainingUpdate={updateTraining}></TrainingHistory>
						</Box>}
						
						{hasFeature(features.COMMAND_MANAGEMENT) && tab == "UNIT" && <Stack flex={1}>
							{unitRoles.map((r, i)=>{
								return <Box key={i}>
									<Stack p={2} direction="row" spacing={1} alignItems="center">
										<AgencyAvatar agency_id={r.agency_id} size={32}/>
										<Box flex={1}>
											<Box>{r.unit_name}</Box>
											<Box className="sub-title " flex={1}>{r.role_names.join(', ')}</Box>
										</Box>
									</Stack>
									<Divider></Divider>
								</Box>
							})}
						</Stack>}
						
					
				</Box>}
			</Box>
		</DialogContent>
		{user && hasRole(admin, roles.ADMIN) && admin.uid != user.uid && <DialogActions>
			<Button size="small" startIcon={<Lock></Lock>} color="error" variant="outlined" onClick={(e)=>{setConfirmDelete(e.currentTarget)}}>Delete This User</Button>
			<Notification
				anchorEl={confirmDelete}
				open={!!confirmDelete}
				maxWidth={300}
				background={theme.palette.error.main}
				color="white"
				onClose={()=>setConfirmDelete(null)}>
					Are you sure?  All history of this user will be deleted permanently.
					<Box pt={1} textAlign="center"><Button size="small" color="white" variant="outlined" onClick={()=>deleteUser(uid)}>Yes, continue</Button></Box>
			</Notification>
		</DialogActions>}
	</Dialog>
}