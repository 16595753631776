import { getAnalytics} from "firebase/analytics";
import { initializeApp } from "firebase/app";

const host = window.location.hostname.toLocaleLowerCase();
var system = process.env.REACT_APP_SYSTEM;
var firebaseConfig = null;

switch(system) {
	case 'prod': 
		firebaseConfig= {
			apiKey: "AIzaSyC5Ty6B_BXfpHQLarAee0jh3TcYOKUwQr4",
			authDomain: "valkyrie-3b338.firebaseapp.com",
			projectId: "valkyrie-3b338",
			storageBucket: "valkyrie-3b338.appspot.com",
			messagingSenderId: "893169436548",
			appId: "1:893169436548:web:e5afdc12535874e23f3fd1"
		  };
		break;
	case 'stage':
		firebaseConfig= {
			apiKey: "AIzaSyC9Fr9uYcC0yRbyV4t1LVaIb2HtfNNdBIY",
			authDomain: "valkyrie-stage.firebaseapp.com",
			projectId: "valkyrie-stage",
			storageBucket: "valkyrie-stage.appspot.com",
			messagingSenderId: "518436357909",
			appId: "1:518436357909:web:2bf7f8672aab4dc79bcb1c"
		};
		break;
	default:
		firebaseConfig= {
			apiKey: "AIzaSyAV1WBBmraOHVDkvp4NobPT1kJXpcovk6c",
			authDomain: "valkyrie-dev-7e23b.firebaseapp.com",
			projectId: "valkyrie-dev-7e23b",
			storageBucket: "valkyrie-dev-7e23b.appspot.com",
			messagingSenderId: "165139817642",
			appId: "1:165139817642:web:4b8eec929cc4bff0da9cd3",
			measurementId: "G-430YZ6FHQW"
		};
		break;
} 

const app = initializeApp(firebaseConfig);
//const _ga = getAnalytics(app);

// function logEvent(event, data) {
// 	gaLog(_ga, event, data);
// }	

export default app;
//export { logEvent };