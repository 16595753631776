import React from 'react';
import { useState} from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Stack, TextField, Card, Box, CardContent, Button, FormControl, Select, InputLabel, MenuItem, FormHelperText, Paper, Divider, Link, OutlinedInput, IconButton, Avatar, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop} from '@mui/material';
import jax from '../helper/jax';
import { useForm, Controller, set } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { setAgencies, signOut } from '../features/data';


import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import  useMediaQuery from '@mui/material/useMediaQuery';
import Icons from '../icons';
import { useNavigate, useParams } from 'react-router-dom';
import UnitSearch from '../components/UnitSearch';
import { ArrowForward, Clear } from '@mui/icons-material';
import UnitSelector from '../components/UnitSelector';
import FeedbackButton from '../components/FeedbackButton';
import { setTenantInfo } from '../features/app';
import { features } from '../features/access';
import { hasFeature } from '../store';

export default function Register(props) {

	const user = useSelector((state) => state.data.user);
	const [affiliations, setAffiliations] = useState([]);
	const {agencies} = useSelector((state) => state.data);
	const [ranks, setRanks] = useState([]);
	const [roles, setRoles] = useState([]);
	const [config, setConfig] = useState({});
	const [unit, setUnit] = useState(null);
	const [registered, setRegistered] = useState(false);
	const [unitLogo, setUnitLogo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [unit_error, setUnitError] = useState(null);
	const theme = useTheme();

	
	const {tenantInfo} = useSelector((state) => state.app);
	var isMobile = useMediaQuery(theme.breakpoints.down("lg"));
	var dispatch = useDispatch();

	var prms = useParams();
	var nav = useNavigate();
	
	function sign_out() {
		
		getAuth().signOut().then(()=>{
			dispatch(signOut());
		});	
	}

	

	const load = async function() {
		if (!loading) {
			setLoading(true);
			var data = await jax.get('/app/auth/register_data')
			setAffiliations(data.affiliations);
			dispatch(setAgencies(data.agencies));
			setRanks(data.ranks);
			setRoles(data.roles);
			setConfig(data.config);
			setLoading(false);
			dispatch(setTenantInfo({...tenantInfo, ...data.tenant}));
			
		}
	}

	useEffect(()=>{
		load();
		console.log('loading');	
	},[]);

	useEffect(()=>{
		if (hasFeature(features.MILITARY_PROFILES) && unit) {
			var abbr = agencies.find((x)=>x.id == unit?.agency_id)?.abbr;
			if (abbr) {
				setUnitLogo(`/images/agency/${abbr}.png`);
			}
		}
	}, [unit]);

	const register = async function(data) {
		setErrorMessage(null);
		if (hasFeature(features.MILITARY_PROFILES) && !unit?.id && (!data.other_unit || !data.other_unit.length)) {
			setUnitError('Please select a unit or enter a unit name.');
			return;
		}
		setUnitError(null);
		

		setLoading(true);
		
		data.unit_id = unit?.id;
		data.continueUrl = prms['*'];
		var console = window.location.host.replace('register.','console.');
		var protocol = window.location.protocol;
		console = `${protocol}//${console}`+window.location.pathname;
		try {
			// var user = await createUserWithEmailAndPassword(auth, data.email, data.password);
			// data.uid = user.user.uid;
			await jax.post('/app/auth/register_user', data);

			//window.location = console;
			setRegistered(true);
			

		} catch (error) {
			if (error.code == 409 || error.code == 'auth/email-already-in-use') {
				setErrorMessage(`A user with this email address already exists.  Please <a class="inherit" href="/">login or request a password reset</a>.`);
			} else if (error.code == 400) {
				setErrorMessage('A user with this military ID already exists.');
			
			} else {
				setErrorMessage('An unexpected error occurred.  Please try again later.');
			}
		} finally {
			setLoading(false);
		}
	}

	const validateEmail = function(value) {
		if (tenantInfo?.settings['REG_RESTRICT_DOMAINS'] && tenantInfo.settings['REG_EMAIL_DOMAINS'].length) {
			var domain = value.split('@')[1];
			if (!tenantInfo.settings['REG_EMAIL_DOMAINS'].includes(domain)) {
				return 'You must use an email address from an approved domain.';
			}
		}
		return true;
	}

	const {control , handleSubmit, watch, setValue, formState: { errors }} = useForm({
		firstName: '',
		lastName: '',
		phone: '',
		role: '',
		milId: '',
		affiliation: '',
		agency: '',
		rank: '',
		email: '',
		password: '',
		password2: '',
		other_unit: ''
	});

	const firstName = watch('firstName');
	const email = watch('email');

	if (user) {
		return <Box>You are currently logged in.  Please <Link onClick={sign_out}>sign out</Link> to register or <Link>continue to the console</Link>.</Box>
	}

	const titleStyle = {whiteSpace: "nowrap", fontWeight:'500',  overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis", marginBottom: '0px', fontSize: { xs: '1.25rem', md: '2rem'}, textAlign:'left', mb: 0, color: 'black'};

	return <Stack className="full-height" direction="column" spacing={2} alignItems="stretch">
	<div>
		
		<form onSubmit={handleSubmit(register)}>

		<Stack p={1} py={3} spacing={2} direction={{xs:"column", lg:"row"}} alignItems="center">
			<Link href="/">
				<img src={tenantInfo?.logo} style={{height: 90}}></img>
			</Link>	
			<Box>
				<Typography variant="h1" mb="0" sx={titleStyle}>{tenantInfo?.name} User Registration</Typography>
				<Typography>Please create your {tenantInfo?.name} profile below.</Typography>
			</Box>
		</Stack>
		
		<Paper  >
			{tenantInfo && tenantInfo.settings["REG_INTRO"] && <><Divider/><Box p={2}>{tenantInfo && tenantInfo.settings["REG_INTRO"]}</Box></>}
			<Stack direction={{lg:"row", xs:'column'}}    >
				<Box sx={{flex:1, p:4}}>
					<Stack  spacing={4} alignItems="stretch" sx={{flex:1}} >
						<Box sx={{flex:1}}>
							<Box className="section-header">Your Information</Box>
							<Box pt={2}>
								
								<Stack spacing={2}>
									<Controller name="firstName" defaultValue='' control={control} rules={{required: 'Required'}}  render={({ field }) => <TextField label="First Name" helperText={errors.firstName?.message} error={!!errors.firstName} size="small" {...field} />}/>
									<Controller name="lastName" defaultValue='' control={control} rules={{required: 'Required'}} render={({ field }) => <TextField label="Last Name" helperText={errors.lastName?.message} error={!!errors.lastName} size="small" {...field} />}/>
									<Controller name="phone" defaultValue='' control={control}  render={({ field }) => <TextField label="Phone" helperText={errors.phone?.message} error={!!errors.phone} size="small" sx={{width: "75%"}} {...field} />}/>
								</Stack>
							</Box>
						</Box>
						
						<Box sx={{flex:1}}>
							<Box className="section-header">Training Level</Box>
							<Box pt={1}>
								
								<Stack spacing={3}>
									<Box>Please select the role that best describes your position in the Valkyrie program.  Leave blank if none.</Box>
									
									<FormControl size="small">
										<InputLabel id="role-label">Select One</InputLabel>
										<Controller name="role" control={control} defaultValue="" render={({ field } ) => <Select
											error={!!errors.role}
											{...field}
											labelId="role-label"
											id="role"
											label="Select One"
											
										>
											{roles.sort((a,b)=>a.sequence-b.sequence).filter(x=>!!x.allow_registration).map( (role) => <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
											
										</Select>
										} />
										<FormHelperText error={!!errors.role}>{errors.role?.message}</FormHelperText>
									</FormControl>
								</Stack>
							</Box>
						</Box>
						
					</Stack>
				</Box>
				{hasFeature(features.MILITARY_PROFILES) && <>
					<Divider variant="middle" orientation={isMobile ? "horizontal" : "vertical"} flexItem sx={{}} >
						{!isMobile && <Icons.Forward sx={{color:"#999"}}/>}
					</Divider>
					<Box sx={{flex:1, p:4}}>
						<Box className="section-header">Military Profile</Box>
						<Box py={1	}>
							
							<Stack spacing={2}>
								<Box>Military personnel, please complete as appropriate.</Box>
								<Controller name="milId" defaultValue='' rules={{required:'Required'}} control={control}  render={({ field }) =>
									<TextField sx={{ width:"75%" }} label="DODID / EDIPI"  size="small" {...field} helperText={errors.milId?.message} error={!!errors.milId}></TextField>
								}/> 
								<FormControl size="small"  >
									<InputLabel id="affiliation-label">Affiliation</InputLabel>
									<Controller name="affiliation" control={control} defaultValue="" render={({ field }) => <Select
										labelId="affiliation-label"
										id="affiliation"
										label="Affiliation"
										{...field}
										>
										<MenuItem key={-1} value="">None</MenuItem>
										{affiliations.map((affiliation) => <MenuItem key={affiliation.id} value={affiliation.id}>{affiliation.name}</MenuItem>)}
									</Select>
									} />
								</FormControl>

								<FormControl size="small"  >
									<InputLabel id="agency-label" >Agency</InputLabel>
									<Controller name="agency" control={control} defaultValue="" render={({ field }) => <Select
										{...field}
										labelId="agency-label"
										id="agency"
										disabled={watch('affiliation') == null}
										label="Agency"
										
									>
										<MenuItem key={-1} value="">None</MenuItem>
										{agencies.filter((v) => v.affiliation_id == watch('affiliation')).map((agency) => <MenuItem key={agency.id} value={agency.id}>{agency.name}</MenuItem>)}

									</Select>
									} />
								</FormControl>

								<FormControl size="small"  >
									<InputLabel id="rank-label" >Rank</InputLabel>
									<Controller name="rank" control={control} defaultValue="" render={({ field }) => <Select
										{...field}
										labelId="rank-label"
										id="rank"
										
										disabled={watch('agency') == null}
										label="Rank"
									>	
										<MenuItem key={-1} value="">None</MenuItem>
										{ranks.filter((v) => v.agency_id == watch('agency')).map((rank) => <MenuItem key={rank.id} value={rank.id}>{rank.name}</MenuItem>)}
									</Select>
									} />
								</FormControl>
								
								<Box className="section-header" pt={1}>Assigned Command</Box>
								<Box pt={1} mt="0 !important">To ensure proper management of your account, please find and select the command you are currently assigned to.</Box>

								<UnitSelector unit={unit} error={unit_error} onSelect={(unit)=>setUnit(unit)}  onTextChange={(t)=>setValue('other_unit', t)} index={config.unit_index} />
							</Stack>
						</Box>
					</Box>
				</>}
				
				<Divider variant="middle" orientation={isMobile ? "horizontal" : "vertical"} flexItem  >
				{!isMobile && <Icons.Forward sx={{color:"#999"}}/>}
				</Divider>
				
				<Box sx={{flex:1, p:4, mt:0}}>
					
					<Stack direction="column" spacing={4} alignItems="stretch">
						<Box sx={{flex:1}}>
							<Box className="section-header">Account Information</Box>
							<Box pt={1}>
								
								<Stack spacing={2}>
									<Box>You will use this information to log in to the {tenantInfo?.name} Portal in the future.</Box>
									<Controller name="email" defaultValue='' rules={{required: 'Required', validate: validateEmail, pattern: {value: /^\S+@\S+$/i, message: 'Enter a valid email'}}} control={control}  render={({ field }) => <TextField label="Email" size="small" autoComplete='username'  helperText={errors.email?.message} error={!!errors.email} {...field} />}/>
									
									<Controller name="password"  defaultValue='' rules={{required: 'Required', minLength: { value:6, message: "Password must be at least 6 characters"}}} control={control}  render={({ field }) => <TextField label="Password" autoComplete="new-password" type="password" helperText={errors.password?.message} error={!!errors.password} size="small" {...field} sx={{width: "75%"}}/>}/>
									<Controller name="password2" defaultValue='' rules={{required: 'Required', validate: (v,fv) => v == fv.password ? true : 'Passwords don\'t match'}} control={control}  render={({ field }) => <TextField label="Confirm Password" autoComplete="new-password" type="password" helperText={errors.password2?.message} error={!!errors.password2} size="small" {...field} sx={{width: "75%"}} />}/>
								</Stack>
							</Box>
						</Box>
						<Box textAlign="center" mt={2} p={2}>
							<FeedbackButton type="submit" disabled={loading} loading={loading} variant="contained">Create Account</FeedbackButton>
							{errorMessage ? <Box textAlign="center" sx={{color:'red'}} p ={2} dangerouslySetInnerHTML={{__html: errorMessage}}></Box> : <></>}
						</Box>
						
					</Stack> 
				</Box>
				
				<Backdrop open={loading} />
			</Stack>
		</Paper>
		
		
		
		
		</form>

		<Dialog open={registered} onClose={()=>setUnit(null)} maxWidth="sm">
			<DialogTitle>Welcome {firstName},</DialogTitle>
			<DialogContent>
				<Typography>Your {tenantInfo.name} account was registered successfully.  You can now login using the email address and password you provided.</Typography>
				
			</DialogContent>
			<DialogActions sx={{justifyContent: "center"}}>
				<Button size="small" endIcon={<ArrowForward/>} onClick={()=>nav(`/${prms['*'] }`)}>Continue to login</Button>
			</DialogActions>
		</Dialog>
	</div>
	</Stack>;
				
}