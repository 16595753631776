
import { Stack,  CardContent, Card, Box, TextField, Button, Typography, Divider, List, ListItemButton, Skeleton, Alert, FormControl, Select, MenuItem, Tooltip, AlertTitle, Collapse, CardActions, IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CourseList from "../components/CourseList";
import jax from "../helper/jax";
import { useNavigate } from "react-router-dom";
import AddTraining from "../components/AddTraining";
import FileExplorer from "../components/FileExplorer";
import { FlexTab, FlexTabs } from "../components/FlexTabs";

import { features, hasAnyRole, hasRole, roleNames, roles } from "../features/access";
import { updateLocalUser } from "../features/data";
import { ArrowForward, EditCalendar, Info, NotificationsNoneTwoTone } from "@mui/icons-material";
import LogRefreshTraining from "../components/LogRefreshTraining.tsx";
import { Verified } from "@mui/icons-material";
import { asDate } from "../helper/util.js";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { hasFeature } from "../store.js";
import { UserRoles } from "../admin/Personnel.js";


function UserProfile(props) {
	const {user, onSubmitVerification} = props;
	const [addTraining, setAddTraining] = useState(false);
	
	return <Card variant="outlined">
		<Stack p={2} spacing={2}>
			<Box sx={{lineHeight: 1.25}}>
				<h3>{user ? user.title : <Skeleton/>}</h3>
				{user.affiliation && <h4 style={{fontWeight: 100, color: "#666"}}>{user ? `${user.affiliation} ${user.agency}` : <Skeleton/>}</h4>}
			</Box>
			{user && user.location && <Box>
				{user.unit_location}
			</Box>}

			<Box>
				
				<UserRoles user={user} roles={user.roles}></UserRoles>
				
				{!!user.unverified_roles?.length && !user.pending_verification && <Alert variant="outlined" severity="warning" className="warning-stripes" sx={{pl: 1, py:1, mt: 1}}>
					<Box ><Typography variant="subtitle2">One or more of your roles cannot be verified.</Typography></Box>
					<Box py={1}>Please submit documentation or evidence of prior training for review in order to verify your roles.</Box>
					<Box mt={1} textAlign="center">
						<Button size="small" variant="contained" color="warning" onClick={()=>setAddTraining(true)}>Add Prior Training</Button>
					</Box>
				</Alert>} 

				{!!user.unverified_roles?.length && !!user.pending_verification && <Alert variant="outlined" severity="warning" className="warning-stripes" sx={{pl: 1, py:1, mt: 1}}>
					<Box ><Typography variant="subtitle2">Your training history is being reviewed.</Typography></Box>
					<Box py={1}>Thank you for submitting evidence of prior training.  An administrator is reviewing the information provided and you will be notified via email when your training is confirmed.</Box>
				</Alert>}
			</Box>
		</Stack>
		<AddTraining open={addTraining} user={user} lock_role={hasRole(user, roles.INSTRUCTOR) ? roles.INSTRUCTOR : roles.GRADUATE}  onClose={()=>setAddTraining(false)} onSubmit={onSubmitVerification}></AddTraining>
	</Card>
}

function RoleResources(props) {
	const user = useSelector((state) => state.data.user);
	const roles = useSelector((state) => state.data.roles);

	const resources = [
		{ name: "Student Resources",  download: true, roles: [1,2,3,4,5,6], item_id: '013C7VTKEO4ZVNGEJI6VD3ZAZ5UZYIGQBO'},
		{ name: "Instructor Resources",  download: true, roles: [4,5,6], item_id: '013C7VTKDFWUBLGSCYLFB26JLRJMVZEBII'},
		// { name: "Program Manager / Director Resources", roles: [5,6], item_id: '013C7VTKBIY5CYGJ4BVJEJRSHJIUQ5LB4M'},
		// { name: "Medical Director Resources", roles: [7], item_id: '013C7VTKH3NYN6Y33DS5CLMIHAMAXHYAJX'},
		{ name: "References", download: false, item_id: '013C7VTKGCXY6YHUHPIVCL3FEEHBXDC6P7'},
	];

	const [my_resources, setMyResources] = useState([]);
	const [activeResources, setActiveResources] = useState(null);

	useEffect(()=>{
		var mr = resources.filter(x=>!x.roles || hasAnyRole(user, x.roles));
		setMyResources(mr);
		if (mr.length > 0) {
			setActiveResources(0);
		}
		
	}, []);

	if (activeResources !== null) {
		const expl = <FileExplorer root={my_resources[activeResources].item_id} downloads={my_resources[activeResources].download} homeFolderName={my_resources[activeResources].name}></FileExplorer>;
		return <Box>
			{my_resources.length > 1 && <>
				<Stack direction="row" spacing={1} p={1} px={2} justifyContent="flex-start" alignItems="center">
					<Box>View:</Box>
					<FormControl>
						<Select size="small" value={activeResources} onChange={(e)=>setActiveResources(e.target.value)}>
							{my_resources.map((r, i)=>{
								return <MenuItem value={i} key={i}>{my_resources[i].name}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Stack>
				<Divider/>
			</>}
			<Box sx={{maxHeight: 400, overflowY: "auto"}}>
				{expl}
			</Box>
		</Box>;
	} else {
		return <Box p={2}>
			<i>There are no resources to show.</i>
		</Box>;
	}
}

function RefresherTraining(props) {
	const {user} = props;
	
	const nextRefresh = moment(user.last_refresh).add(process.env.REACT_APP_REFRESHER_EVERY_X_DAYS, 'days');
	const daysUntil = nextRefresh.diff(moment(), 'days');
	const theme = useTheme(); 
	const [logRefreshTraining, setLogRefreshTraining] = useState(false);

	var color = "#000";
	var comingDue = false;
	var overdue = false;

	var age = moment().diff(user.last_refresh, 'days');
	var percent = age / process.env.REACT_APP_REFRESHER_EVERY_X_DAYS;
	percent = Math.min(percent,1)*100;
	// percent = 10;

	var color = theme.palette.success;
	if (percent >= 100) {
		overdue = true;
		color = theme.palette.error;
	} else if (percent > 85) {
		comingDue = true;
		color = theme.palette.warning;
	}

	return <Card variant="outlined" color="info">
		<LogRefreshTraining open={logRefreshTraining} onClose={()=>setLogRefreshTraining(false)}></LogRefreshTraining>
		<CardContent>
			<Stack direction="row" alignItems="center">
				<Box flex={1}>
					<h3>Refresher Training</h3>
				</Box>
				<Box>
					<Tooltip title={<Box color="white" fontSize="0.9rem">Refresher training is required every {process.env.REACT_APP_REFRESHER_EVERY_X_DAYS} days to maintain your Valkyrie certification status.</Box>} arrow>
						<Info color="disabled"></Info>
					</Tooltip>
				</Box>
			</Stack>
			<Stack spacing={2} className="sub-title" mt={1}>					
				<Box>
					{!!overdue && <Box fontWeight="bold" color={color.main}>You are overdue for refresher training.</Box>}
					{!!comingDue && <Box fontWeight="bold" color={color.main}>It is time for your refresher training</Box>}
					
					<Box mt={0.5} position="relative" border={`solid 1px ${color.light}`}  borderRadius={theme.shape.borderRadius+'px'} overflow="hidden">
						<Box position="relative" backgroundColor={color.pale} width="100%" height="16px">
							<Box width={percent+'%'} backgroundColor={color.light} height="100%"></Box>
						</Box>
					</Box>
				</Box>		

				{!overdue && <Box>You are due for refresher training in <span style={{color: color.main, fontWeight: 'bold'}}>{daysUntil} day{daysUntil == 1 ? '' : 's'}</span>.</Box>}

				<Box>
					<b>Your last training:</b> {asDate(user.last_refresh)} ({age} day{age == 1 ? '' : 's'} ago).  
				</Box>

				<Box>If you have recently participated in refresher training, please submit it for approval below.</Box>
				<Box textAlign="center" pt={1}>
					<Button size="small" variant="contained" color="success" onClick={()=>setLogRefreshTraining(true)} startIcon={<EditCalendar/>}>Submit Refresher Training</Button>
				</Box>
			</Stack>
		</CardContent>
	</Card>;
}

export default function Home() {
	
	const [lookup, setLookup] = useState({});
	const [findError, setFindError] = useState(null);
	const nav = useNavigate();
	const user = useSelector((state) => state.data.user);
	const config = useSelector((state) => state.data.config);
	const alerts = useSelector((state) => state.data.alerts);
	const dispatch = useDispatch();
	const theme = useTheme();

	const findCourse = async function() {
		try {
			setFindError(null);
			var course = await jax.get(`/app/courses/${lookup.trim()}`);
			nav(`/courses/${course.code}`);
		} catch (error) {
			setFindError('This course code was not found.');
		}
	}
	useEffect(()=>{
			
	}, []);

	const setPendingVerification = function() {
		dispatch(updateLocalUser({pending_verification: true}));
	}

	return <Stack direction={{xs: "column", lg: "row"}} spacing={2} alignItems={{xs: "stretch", lg: "flex-start"}}>
			<Stack  spacing={2} alignItems="stretch" sx={{flex:1}}>
				<UserProfile user={user} onSubmitVerification={setPendingVerification}></UserProfile>
				
				{hasFeature(features.REFRESHER_TRAINING) && hasAnyRole(user, [roles.INSTRUCTOR, roles.IUT, roles.GRADUATE]) && user.verified == 1 && <RefresherTraining user={user}></RefresherTraining>}
				{false && hasAnyRole(user, [roles.INSTRUCTOR, roles.PROGRAM_MGR, roles.PROGRAM_DIR]) && <Card color="info" variant="outlined" sx={{p:0, py:"0 !important", "& .MuiAlert-message": {p:"0 !important"}}}>
					<Box className="card-header" bgcolor="info.main" color="info.contrastText" fontSize="initial" textAlign="center" fontWeight="bold">Refresher Training</Box>
					
					<Box p={2} bgcolor="white.main">
						<AlertTitle>Instructors...</AlertTitle>
						Please record refresher training events to ensure training history is accurate for all personnel.
						<Box mt={2} textAlign="center">
							{/* <Button size="small" variant="contained" color="info" onClick={()=>setLogRefreshTraining(true)} startIcon={<EditCalendar/>}>Record Refresher Training</Button> */}
						</Box>
					</Box>
				</Card>}
				{/* <Card variant="outlined">
					<Box sx={{color: "black"}} p={2} alignItems="center" >
						<Stack spacing={2}>
							<div>Join or view a course by entering the course code below:</div>
							<TextField label="Course Code" variant="outlined" size="small" error={!!findError} helperText={findError} onChange={(e)=> setLookup(e.target.value)}sx={{mt:2}}></TextField>
							<Button variant="contained" color="primary" sx={{mt:2}} onClick={findCourse}>Find Course</Button>
						</Stack>							
					</Box>
					
				</Card> */}

			</Stack>
			<Stack spacing={2} flex={2}>
				{!!alerts.length && <Collapse in={!!alerts?.length}>
					<Alert variant="filled" severity="error" sx={{py:0.5}} icon={<NotificationsNoneTwoTone/>} action={
						<Button color="inherit" size="small" onClick={()=>nav('?alerts')}>
						VIEW NOTIFICATIONS
						</Button>
					}>
						You have {alerts?.length} notifications requiring attention.
					</Alert>
				</Collapse>}
				<Card variant="outlined"  p={0}>
					<div className="card-header">Your Active Courses</div>
					<Divider></Divider>
					{user.courses && user.courses.some(x=>!x.closed_on) ? <CourseList courses={user.courses.filter(x=>!x.closed_on)}></CourseList> : <Box p={2} pt={4} textAlign="center"><i>You are not currently enrolled in any courses.</i></Box>}
					{!!user?.courses?.length && <Divider></Divider>}
					<CardActions sx={{justifyContent:"center"}} >
						<form onSubmit={(e)=>{e.preventDefault(); findCourse();}}>
							<Stack direction="row" flex={1} justifyContent="space-around" alignItems="center" spacing={1}>
								<Box>
									<Typography variant="body1" fontWeight="500">Find a course by course code:</Typography></Box>
								<Box>
									<TextField label="Course Code" variant="outlined" size="small" error={!!findError} helperText={findError} onChange={(e)=> setLookup(e.target.value)} sx={{pr:0}} InputProps={{
										sx: {pr:0},
										endAdornment: <InputAdornment position="end">
											<IconButton  type="submit">
												<ArrowForward/>
											</IconButton>
										</InputAdornment>
									}}></TextField>
								</Box>
							</Stack>
						</form>
					</CardActions>
				</Card>
				<Card variant="outlined" sx={{flex:2}} p={0}>
					<div className="card-header">Your Recent Courses</div>
					<Divider></Divider>
					{user.courses && !!user.courses.find(x=>x.closed_on) ? <CourseList courses={user.courses.filter(x=>x.closed_on)}></CourseList> : <Box p={2} py={4} textAlign="center"><i>You have no recent courses to display.</i></Box>}
				</Card>
				{false && user.valk_role_id && <Card variant="outlined" sx={{flex:2}} p={0}>
					<div className="card-header">Resources</div>
					<RoleResources></RoleResources>
				</Card>}
			</Stack>
		</Stack>;

		
		
	
}

